function isMobileDevice() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgent.substr(0, 4)))
}

function cookieValue(cookieName) {
    var cookies = decodeURIComponent(document.cookie).split('; ');
    for (var i = 0, len = cookies.length; i < len; i++) {
        if (cookies[i].indexOf(cookieName + '=') === 0)
            return cookies[i].substring((cookieName + '=').length);
    }

    return null;
}

function setCookieValue(cookieName, value, expiresDays) {
    var d = new Date();
    d.setTime(d.getTime() + (expiresDays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + '=' + value + ';' + expires + ';path=/';
}

function getQueryParams(url) {
    var queryParams = {};
    var anchor = document.createElement("a");
    anchor.href = url;
    var queryStrings = anchor.search.substring(1);
    var params = queryStrings.split("&");

    for (var i = 0; i < params.length; i++) {
        var pair = params[i].split("=");
        queryParams[pair[0]] = decodeURIComponent(pair[1]);
    }
    return queryParams;
};

function showLoader(cb) {
    $('#popupLoader').show();
    $('#popupFade').show();
    cb && cb()
}

function hideLoader(cb) {
    $('#popupLoader').hide();
    $('#popupFade').hide();
    cb && cb()
}

const lockout = {
    getScrollBarWidth: () => {
        const div = document.createElement('div');
        div.style.cssText = "overflow: auto scroll; width: 50px; height: 50px; position: absolute;";

        document.body.append(div);
        const scrollWidth = div.offsetWidth - div.clientWidth;

        div.remove();
        return scrollWidth;
    },
    setScrollbarWidthCssVar: () => {
        document.documentElement.style.setProperty('--scrollbarWidth', `${lockout.getScrollBarWidth()}px`);
    },
    on: () => {
        window.site.html.addClass('lockout');
    },
    off: () => {
        window.site.html.removeClass('lockout');
    },
    toggle: () => {
        window.site.html.toggleClass('lockout');
    }
}
lockout.setScrollbarWidthCssVar();

const debounce = (func, wait = 250) => {
    let timeout;
    return (event) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(event), wait);
    };
};

const scrollTo = (offset, speed) => {
    var value = parseInt(offset, 10);
    if (value < 0 && $('html, body').scrollTop !== value) return;
    $('html, body').animate({ scrollTop: value }, speed || 500);
}

const getCssVar = (varName) => getComputedStyle(document.documentElement).getPropertyValue(`--${varName}`);
const breakpoints = {
    mobileLarge: parseInt(getCssVar('bpMobileLarge')),
    tablet: parseInt(getCssVar('bpTablet')),
    tabletMid: parseInt(getCssVar('bpTabletMid')),
    tabletLarge: parseInt(getCssVar('bpTabletLarge')),
    desktop: parseInt(getCssVar('bpDesktop')),
    large: parseInt(getCssVar('bpLarge')),
    exlarge: parseInt(getCssVar('bpExLarge')),
}

// For this to work without the hassle of waiting for the elements to exist throught MutationObserver/etc make sure site.js is added to the end of <body>
// Also were not exporting htis object as it would need importing to *every* file, must easier just to reference the window obj
window.site = {
    doc: $(document),
    body: $('body'),
    win: $(window),
    html: $('html')
}

export {
    breakpoints,
    cookieValue,
    debounce,
    getQueryParams,
    hideLoader,
    isMobileDevice,
    lockout,
    scrollTo,
    setCookieValue,
    showLoader,
}
